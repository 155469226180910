import React from "react"
import { Text, Box } from "rebass"

export const Card = props => (
  <Box
    sx={{
      color: "primary",
      display: "flex !important",
      flexDirection: "column",
      justifyContent: "space-between",
      fontSize: 3,
      backgroundColor: "#101010",
      borderRadius: "box",
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.08)",
      p: [3, 3, 4],
      height: ["300px", "300px", "350px"],
    }}
    {...props}
  />
)
export const CardText = props => (
  <Text
    sx={{
      fontSize: [1, 1, 3],
    }}
    {...props}
  />
)
export const Deponent = props => (
  <Box sx={{ fontWeight: "bold", fontSize: 2 }} {...props} />
)
export const DeponentWrapper = props => (
  <Box sx={{ display: "flex", mt: [2, 2, 5] }} {...props} />
)
export const CardLogo = props => (
  <Box sx={{ height: 80, width: 80, mr: 4 }} {...props} />
)
export const DeponentPosition = props => <Text {...props} />
export const PagingIndicatorWrapper = props => (
  <Box
    as="ul"
    sx={{
      display: "flex",
      listStyle: "none",
      flexWrap: "wrap",
      li: {
        mr: 2,
        padding: "6px 0",
        cursor: "pointer",
      },
    }}
    {...props}
  />
)
